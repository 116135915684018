@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

* {
  background: url() no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* overflow-x: hidden; */
}
html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  position: relative;
}
